<template>
  <v-form>
    <v-snackbar v-model="isSnackbarVisible" :color="snackbar.color" :timeout="snackbar.timeout" top right auto-height>
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large
          >{{ snackbar.icon == 'success' ? icons.mdiCheckBold : icons.mdiCheckBold }}
        </v-icon>

        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-row>
      <v-col cols="6">
        <label for="">intervalo Mapa</label>
        <!-- <input class="form-control" step="0.01" v-model="data.base_menora6km" type="number" name="" id="" /> -->
        <v-text-field step="0.01" v-model="data.intervaloMapa" outlined dense></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <label for="">Valor Córdoba</label>
        <!-- <input class="form-control" step="0.01" v-model="data.base_menora6km" type="number" name="" id="" /> -->
        <v-text-field step="0.01" v-model="data.c_cordoba" outlined dense></v-text-field>
      </v-col>
      <v-col cols="6">
        <label for="">Valor Dolar</label>
        <!-- <input class="form-control" step="0.01" v-model="data.base_menora6km" type="number" name="" id="" /> -->
        <v-text-field step="0.01" v-model="data.c_dolar" outlined dense></v-text-field>
      </v-col>
    </v-row>
    <div class="row">
      <div class="col-12">
        <label for="">terminos Driver</label>
        <!-- <input class="form-control" step="0.01" v-model="data.base_mayoroiguala6km" type="number" name="" id="" /> -->
        <textarea class="col-12" rows="15" v-model="data.terminos_driver"></textarea>
      </div>
    </div>

    <!-- <v-checkbox v-model="checkbox" label="Remember me" class="mt-0"></v-checkbox> -->

    <!-- <v-btn color="primary"> Submit </v-btn> -->

    <v-btn @click="saveData()" outlined class="mx-2 custom-search-botton"> Guardar cambios </v-btn>
  </v-form>
</template>

<script>
import {
  mdiAccountCheckOutline,
  mdiCheckBold,
  mdiDeleteOutline,
  mdiMapMarkerRadius,
  mdiMapMarkerRadiusOutline,
  mdiMapSearch,
  mdiPencil,
  mdiPlusCircle,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  data() {
    return {
      icons: {
        mdiMapMarkerRadiusOutline,
        mdiMapMarkerRadius,
        mdiDeleteOutline,
        mdiPencil,
        mdiPlusCircle,
        mdiCheckBold,
        mdiMapSearch,
        mdiAccountCheckOutline,
      },
      isSnackbarVisible: false,
      message: '',
      messageColor: 'primary',
      icon: '',
      snackbar: {
        color: 'success',
        icon: 'check_circle',
        mode: 'multi-line',
        title: 'Success',
        text: 'That worked, hoorah.',
        visible: true,
      },
      data: {
        intervaloMapa: 0,
        terminos_driver: '',
      },
    }
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async saveData() {
      try {
        let row = { ...this.data }
        let res
        delete row.id
        res = await this.$store.dispatch('put', { path: 'configuracion/modify/1', data: row })
        // }
        //this.getData()
        this.snackbar = {
          color: 'success',
          icon: 'success',
          mode: 'multi-line',
          title: 'Muy bien!',
          text: 'Registro procesado correctamente!.',
          timeout: '2500',
        }
        this.isDialogVisible = false
        this.isSnackbarVisible = true
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    async getData() {
      //res = await this.$store.dispatch('post', { path: '/multipuntos/new/', data: data })

      let resTarifas = await this.$store.dispatch('get', { path: 'configuracion/' })

      this.data = resTarifas[0]
      console.log(this.data)
    },
  },
  setup() {
    const firstname = ref('')
    const email = ref('')
    const mobile = ref()
    const password = ref()
    const checkbox = ref(false)

    return {
      firstname,
      email,
      mobile,
      password,
      checkbox,
    }
  },
}
</script>
<style scoped>
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}
.custom-search-botton {
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  background: #f2c309;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 10px;
  z-index: 2;
}
</style>
